
import Vue, { PropType } from 'vue';
import formRulesGenerator from '@shell/utils/validators/formRules';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';

interface Data {
  valid: { [key: string]: boolean }
}

// Data, Methods, Computed, Props
export default Vue.extend<Data, any, any, any>({
  components: {
    Checkbox,
    LabeledInput,
    LabeledSelect,
  },

  props: {
    chart: {
      type:     Object as PropType<{ [key: string]: object }>,
      required: true
    },
    value: {
      type:     Object as PropType<{ [key: string]: any }>,
      required: true
    },
    title: {
      type:    String,
      default: 'Settings'
    },
    mode: {
      type:     String,
      required: true
    },
    disabled: {
      type:    Boolean,
      default: false
    },
  },

  data() {
    return { valid: {} };
  },

  watch: {
    valid(neu) {
      this.$emit('valid', neu);
    }
  },

  methods: {
    rules(key: string, min: any, max: any) {
      const frg = formRulesGenerator(this.$store.getters['i18n/t'], { key });
      const minRule = frg.minValue(min);
      const maxRule = frg.maxValue(max);

      return (value: string) => {
        const messages = [];

        if (value) {
          const minRes = minRule(value);

          if (minRes) {
            messages.push(minRes);
          }

          const maxRes = maxRule(value);

          if (maxRes) {
            messages.push(maxRes);
          }
        }
        Vue.set(this.valid, key, !messages.length);

        return messages.join(',');
      };
    },

    numericPlaceholder(setting: any) {
      if (setting.maximum && setting.minimum) {
        return `${ setting.minimum } to ${ setting.maximum }`;
      } else if (setting.maximum) {
        return `<= ${ setting.maximum }`;
      } else if (setting.minimum) {
        return `>= ${ setting.minimum }`;
      } else {
        return '';
      }
    },

    onInputCheckbox(key: string, value: boolean) {
      Vue.set(this.value, key, value ? 'true' : 'false');
    }
  },
});
